<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap" style="gap:16px;row-gap:0;" ref="tabletopbar">
						<v-btn-toggle mandatory dense v-model="filter" @change="loadData">
							<v-btn>全部</v-btn>
							<v-btn>未反馈</v-btn>
							<v-btn>有反馈</v-btn>
						</v-btn-toggle>
						<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto"
							append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
						<v-select placeholder="术后等级" dense clearable hide-details :items="['A','B','C1','C2']" v-model="level" @change="loadData" style="flex: 0 1 auto"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdatestext" placeholder="手术日期" readonly v-bind="attrs" style="flex: 0 1 auto;width:240px;"
									v-on="on" dense hide-details append-icon="mdi-close" @click:append="clearpdates"></v-text-field>
							</template>
							<v-date-picker v-model="pdates" @change="applypdates" range no-title scrollable></v-date-picker>
						</v-menu>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
				<template v-slot:item.idcard="{ item }">
					{{getMaskedIdcard(item)}}
				</template>
				<template v-slot:item.gender="{ item }">
					{{getGender(item)}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto">
					<v-tabs>
					<v-tab>反馈</v-tab>
					<v-tab-item>
					<div v-if="!!selected[0].followup24 || !!selected[0].followup72" class="mt-1">
						<v-btn v-if="!!selected[0].followup24" color="primary" class="ma-2" :loading="saving"
							:disabled="saving||!!selected[0].progress.followupConfirm24||Date.now() < selected[0].followup24"
							@click="confirmFollowup(24)">24小时术后跟进确认</v-btn>
						<v-btn v-if="!!selected[0].followup72" color="primary" class="ma-2" :loading="saving"
							:disabled="saving||!!selected[0].progress.followupConfirm72||Date.now() < selected[0].followup72"
							@click="confirmFollowup(72)">72小时术后跟进确认</v-btn>
						<v-divider class="mt-1"/>
					</div>
					<v-row style="width:98%" class="mt-2 ml-2">
						<v-col cols="12" md="3">
							2小时反馈
							<div class="caption">反馈时间{{formatTime(selected[0].progress.feedback2)}}</div>
							<div v-for="(item, i) in selected[0].feedback2" :key="item.Q">
								<v-radio-group :value="item.A" :label="item.Q" dense readonly row hide-details>
									<v-radio v-for="a in item.AA" :key="a" :label="a" :value="a"/>
								</v-radio-group>
							</div>
							<v-btn v-if="!!selected[0].feedback2" color="primary" class="mt-4" :loading="saving"
								:disabled="saving||!!selected[0].progress.feedbackConfirm2" @click="confirm(2)">确认收到反馈</v-btn>
						</v-col>
						<v-col cols="12" md="3">
							24小时反馈
							<div class="caption">反馈时间{{formatTime(selected[0].progress.feedback24)}}</div>
							<div v-for="(item, i) in selected[0].feedback24" :key="item.Q">
								<v-radio-group :value="item.A" :label="item.Q" dense readonly row hide-details>
									<v-radio v-for="a in item.AA" :key="a" :label="a" :value="a"/>
								</v-radio-group>
							</div>
							<v-btn v-if="!!selected[0].feedback24" color="primary" class="mt-4" :loading="saving"
								:disabled="saving||!!selected[0].progress.feedbackConfirm24" @click="confirm(24)">确认收到反馈</v-btn>
						</v-col>
						<v-col cols="12" md="3">
							72小时反馈
							<div class="caption">反馈时间{{formatTime(selected[0].progress.feedback72)}}</div>
							<div v-for="(item, i) in selected[0].feedback72" :key="item.Q">
								<v-radio-group :value="item.A" :label="item.Q" dense readonly row hide-details>
									<v-radio v-for="a in item.AA" :key="a" :label="a" :value="a"/>
								</v-radio-group>
							</div>
							<v-btn v-if="!!selected[0].feedback72" color="primary" class="mt-4" :loading="saving"
								:disabled="saving||!!selected[0].progress.feedbackConfirm72" @click="confirm(72)">确认收到反馈</v-btn>
						</v-col>
					</v-row>
					</v-tab-item>
					<v-tab :disabled="!viewrpt">报告</v-tab>
					<v-tab-item :disabled="!viewrpt">
						<v-container fluid v-if="rpts.length > 0" class="mt-1">
							<v-divider/>
							<v-row class="mt-2">
								<v-card v-for="(r,idx) in rpts" :key="idx" class="ma-2" max-width="300">
									<v-img v-if="r.isPdf" @click.stop="showPdf(idx)" height="300">
										<object :data="r.valueOf()+'#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" width="100%" height="100%" style="pointer-events:none;"/>
									</v-img>
									<v-img v-else :src="r.valueOf()" lazy-src="../assets/logo.png" width="300" height="300" @click.stop="showImage(idx)">
										<template v-slot:placeholder>
											<v-row class="fill-height ma-0" align="center" justify="center">
												<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-card>
							</v-row>
						</v-container>
					</v-tab-item>
					</v-tabs>
	            </div>
			</pane>
		</splitpanes>
		<viewer v-if="rpts.length > 0" @inited="inited" ref="viewer" :images="rpts.filter(x=>!x.isPdf)" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src.valueOf()" :key="src.valueOf()">
			</template>
		</viewer>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import store from '../store.js'

    export default {
        data() {
            return {
                authed: false,
				viewrpt: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'术后等级', value:'endoscopyResult.followupLevel', width:100},
                    {text:'活检', value:'endoscopyResult.biopsy', width:120},
                    {text:'息肉', value:'endoscopyResult.polyp', width:120},
                    {text:'特殊跟进', value:'endoscopyResult.needFollowup', width:120},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				saving: false,
				filter: 0,
				options: {},
				totalItems: 0,
				pname: '',
				tableheight: undefined,
				level: null,
				rpts: [],
				menu1: false,
				pdates: [],
            }
        },
		computed: {
			pdatestext() {
				return this.pdates.join(' ~ ');
			},
		},
        mounted() {
			this.authed = this.$hasPrivilege('术后管理') || this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
			this.viewrpt = this.$hasPrivilege('术后管理');
			this.formatTime = formatTime;
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchData();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				setTimeout(() => {
					const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			applypdates() {
				this.menu1 = false;
				this.fetchData();
			},
			clearpdates() {
				this.pdates = [];
				this.fetchData();
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{endoscopyResult:_.exists(true)}];
				if (this.pname) filter.push({'pii.username':this.pname});
				if (this.$hasPrivilege('术后管理')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				filter.push({hide:_.neq(true)});
				if (this.level) filter.push({'endoscopyResult.followupLevel':this.level});
				filter.push({region:store.currentRegion});
				const filters = [
					[],
					[{feedback2:_.exists(false)}, {feedback24:_.exists(false)}, {feedback72:_.exists(false)}],
					[_.or([{feedback2:_.exists(true)}, {feedback24:_.exists(true)}, {feedback72:_.exists(true)}])]
				];
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push(
						_.and([{'progress.operative':_.gt(d1)}, {'progress.operative':_.lt(d2)}]),
					);
				}
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
				if (!item.QA) return '';
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			async confirm(hours) {
                this.saving = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'feedbackConfirm', data:{id, hours}}});
					this.selected[0]['progress']['feedbackConfirm'+hours] = new Date();
					await this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.saving = false;
			},
			async confirmFollowup(hours) {
                this.saving = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'followupConfirm', data:{id, hours}}});
					this.selected[0]['progress']['followupConfirm'+hours] = new Date();
					await this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.saving = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			showPdf(idx) {
				this.curpdf = this.rpts[idx].valueOf();
				this.dlgPdf = true;
			},
			async fetchRpts() {
				this.rpts = [];
				const s = this.selected[0];
				const {username, dob} = s.pii;
				const res = await this.$getEdReports({username, dob});
				const s1 = res.map(x => new String(x.imagesrc));
				const s2 = s.pathologyReports ? s.pathologyReports.map(x => new String(x)) : [];
				s2.forEach(x => {
					const str = x.valueOf();
					x.isPdf = str.substring(str.length - 3).toLowerCase() === 'pdf';
				});
				this.rpts = s2.concat(s1);
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.fetchRpts();
			},
		},
        components: { Splitpanes, Pane }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>